import { GEOZONE_WITH_EXHIBIT_HALL_FRAGMENT } from '@/graphql/_Fragments/Geozone/WithExhibitHall';

export const SESSION_ENTITY_INFO_FRAGMENT = `
  fragment sessionEntityInfoFragment on Session {
    uid
    schemaCode
    name
    startTime
    endTime
    free
    price
    credits
    creditsUnit
    geozones: _geozonesOfEdition {
      ...geozoneWithExhibitHallFragment
    }
  }
  ${GEOZONE_WITH_EXHIBIT_HALL_FRAGMENT}
`;
